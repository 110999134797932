window.bootstrap = require("bootstrap");

import "@fortawesome/fontawesome-free/js/all";

import "../scss/app.scss";

const preview = document.getElementById("preview");
const htmlContent = document.getElementById("htmlContent");

/*#########################################################
    ペーストされた時に発火するイベント
#########################################################*/
addEventListener("paste", function (e) {
    e.preventDefault();
    let html_data = e.clipboardData.getData("text/html");
    if (!html_data) {
        html_data = e.clipboardData.getData("text/plain");
    }
    const dom_parser = new DOMParser();
    const doc = dom_parser.parseFromString(html_data, "text/html");
    const body = doc.body;
    removeComments(body); // コメントを削除する関数を呼び出し
    removeOtherAttributes(body); // colspanとrowspan以外の属性を削除する関数を呼び出し
    replaceTagWithItsContent(body, 'span'); // spanタグの除去
    replaceTagWithItsContent(body, 'o:p'); // o:pタグの除去
    replaceTagWithItsContent(body, 'col'); // colタグの除去
    replaceTagWithItsContent(body, 'colgroup'); // colgroupタグの除去
    const bodyInnerHTML = body.innerHTML; // bodyのHTML内容を文字列として取得
    // preview要素とhtmlContent要素に、bodyのHTML内容を設定
    preview.innerHTML = bodyInnerHTML; // innerHTMLを使用してHTML内容を設定
    htmlContent.value = bodyInnerHTML; // textareaのvalueにはinnerHTMLの内容を設定
});

/*#########################################################
    コメントを削除する関数
#########################################################*/
window.removeComments = (element) => {
    const childNodes = element.childNodes;
    for (let i = childNodes.length - 1; i >= 0; i--) {
        const node = childNodes[i];
        if (node.nodeType === 8) {
            // 8はコメントノード
            node.parentNode.removeChild(node);
        } else if (node.nodeType === 1) {
            // 1は要素ノード
            removeComments(node);
        }
    }
};

/*#########################################################
    指定された要素からcolspanとrowspan以外の属性を削除
#########################################################*/
window.removeOtherAttributes = (element) => {
    if (element.attributes) {
        // 属性リストを配列に変換して、逆順で処理（削除中にインデックスのずれを防ぐ）
        const attributes = Array.from(element.attributes).reverse();
        attributes.forEach(attr => {
            if (attr.name !== "colspan" && attr.name !== "rowspan") {
            element.removeAttribute(attr.name);
            }
        });
    }
    // 子要素に対して再帰的に処理
    element.childNodes.forEach(child => {
        if (child.nodeType === 1) { // 要素ノード
            removeOtherAttributes(child);
        }
    });
}

/*#########################################################
    特定のタグを削除する関数
#########################################################*/
window.replaceTagWithItsContent = (element, tagName) => {
    const elements = Array.from(element.getElementsByTagName(tagName));
    elements.forEach(el => {
        // 親ノードを取得
        const parent = el.parentNode;
        // 各子ノードを親ノードに移動
        while (el.firstChild) {
            parent.insertBefore(el.firstChild, el);
        }
        // タグ自体を削除
        parent.removeChild(el);
    });
}
/*#########################################################
    特定のタグ（この例では<p>）を削除し、その中身を保持して後に<br>を挿入する関数
#########################################################*/
window.replacePTagWithBr = (element) => {
    const pTags = Array.from(element.getElementsByTagName('p'));
    pTags.forEach(p => {
        const parent = p.parentNode;
        const br = document.createElement('br');
        while (p.firstChild) {
            parent.insertBefore(p.firstChild, p);
        }
        parent.insertBefore(br, p); // <p>の内容の後に<br>を挿入
        parent.removeChild(p); // 元の<p>タグを削除
    });
};

/*#########################################################
    フォームの値を取得する
#########################################################*/
window.get = () => {
    const source = clear(htmlContent.value);
    // プレビューに表示
    preview.innerHTML = source;
    htmlContent.value = source;
    copyText();
};

/*#########################################################
    置換リスト
#########################################################*/
const replacements = {
    strong: [/<(\/?)b>/g, "<$1strong>"],
    table_start: [/<table>/g,'<div class="table-responsive"><table class="table table-bordered align-middle">',],
    table_end: [/<\/table>/g, "</table></div>"],
    nbsp: [/&nbsp;/g, " "],
    newline: [/[\r\n|\n|\r]+/g, "\n"],
};

/*#########################################################
    取得した値を変換する
#########################################################*/
export const clear = (source) => {
    Object.entries(replacements).forEach(([key, [pattern, replacement]]) => {
        // チェックボックスによって処理をスキップ
        if (document.getElementById('table').checked == false && key === 'table_start') return;
        if (document.getElementById('table').checked == false && key === 'table_end') return;
        if (document.getElementById('nbsp').checked == false && key === 'nbsp') return;
        if (document.getElementById('newline').checked == false && key === 'newline') return;
        // スキップされなかった場合に置換処理を実行
        source = source.replace(pattern, replacement);
    });
    if (document.getElementById('p').checked) {
        const dom_parser = new DOMParser();
        const doc = dom_parser.parseFromString(source, "text/html");
        replacePTagWithBr(doc.body); // pタグの除去
        source = doc.body.innerHTML;
    }
    return source;
};

/*#########################################################
    テキストボックスの内容をコピーする
#########################################################*/
export const copyText = () => {
    // テキストボックスの内容を取得
    var htmlContent = document.getElementById("htmlContent");
    
    // テキストを選択
    htmlContent.select();
    htmlContent.setSelectionRange(0, 99999); // モバイルデバイス用の選択
    
    const toastLiveExample = document.getElementById('liveToast');
    const toast = new bootstrap.Toast(toastLiveExample);
    const toastBody = document.getElementById('toast-body');
    // クリップボードにコピー
    navigator.clipboard.writeText(htmlContent.value).then(function () {
        toastBody.innerHTML = 'HTMLをコピーしました';
        toast.show();
    }, function(err) {
        toastBody.innerHTML = 'テキストのコピーに失敗しました';
        toast.show();
    });
}